.ReceiptFormStyles {

  background: #595959;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  color: #FFFFFF;
}

.ReceiptFormStyles > p {

  color: red;
  text-align: center
}
